import { Box, Button, Stack, Typography } from '@mui/material';
import { palette } from '../../myTheme';
import TCImage1 from '../../components/images/TCImage1.jpg';
import TCImage2 from '../../components/images/TCImage2.jpg';
import TCImage3 from '../../components/images/TCImage3.jpg';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function TCAppPage() {
  const isScreenSmall = useMediaQuery('(max-width: 900px)');
  return (
    <Box margin={isScreenSmall ? 6 : 12}>
      <Stack spacing={8}>
      <Stack direction="row" style={{ paddingBottom: 8, justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography fontWeight={800} fontSize={isScreenSmall ? 18 : 70}>
          Bill Splitter and Tip Calculator
        </Typography>
        <Button style={{ marginTop: isScreenSmall ? 0 : 13, maxHeight: isScreenSmall ? 40 : 80, fontSize: isScreenSmall ? 16 : 30 }} onClick={() => window.history.back()}>
            Back
          </Button>
      </Stack>

        <Typography style={{ textAlign: isScreenSmall ? 'center' : 'start' }} fontStyle={{ color: palette.white }} fontWeight={400} fontSize={isScreenSmall ? 14 : 20}>
          This is a lightweight and fully featured tip calculator and bill splitter. You can evenly split a bill and tip
          for up to 20 people. This app also features a round up feature to round your bill up to the nearest dollar if
          you so desire. Built with SwiftUI it is incredibly lightweight and performant.
        </Typography>
        <Stack style={{ justifyContent: 'space-evenly' }} direction={'row'}>
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '550px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={TCImage1}
          />
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '550px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={TCImage2}
          />
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '550px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={TCImage3}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
