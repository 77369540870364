import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { palette } from '../myTheme';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function Home() {
  const navigate = useNavigate();
  const navigateToExperience = () => navigate('/experience');
  const navigateToProjects = () => navigate('/projects');
  const navigateToContact = () => navigate('/contact');

  const isScreenSmall = useMediaQuery('(max-width: 900px)');

  return (
    <Stack padding={isScreenSmall ? 6 : 12} style={{ alignItems: 'center' }}>
      <Card variant="outlined" style={{ maxWidth: isScreenSmall ? '125%' : 1800, backgroundColor: palette.ui_navy_80, padding: 40 }}>
        <CardContent>
          <Stack style={{ alignItems: isScreenSmall ? 'center' : 'space-between' }} gap={4} spacing={4}>
            <Stack style={{ paddingBottom: -10, marginBottom: -20 }} gap={0}>
              <Typography
                style={{ marginTop: isScreenSmall ? -20 : 0 ,paddingBottom: isScreenSmall ? 12 : 0, marginBottom: isScreenSmall ? 12 : 0,marginLeft: 4, textAlign: isScreenSmall ? 'center' : 'start' }}
                fontWeight={500}
                variant="h4"
              >
                Hello, my name is
              </Typography>
              <Typography
                style={{ paddingTop: -50, marginTop: -20, paddingBottom: isScreenSmall ? 8 : 0, marginLeft: 4,textAlign: isScreenSmall ? 'center' : 'start' }}
                fontStyle={{ color: palette.white }}
                fontWeight={800}
                fontSize={isScreenSmall ? '60px' : '100px'}
              >
                LINCOLN ANDERSON
              </Typography>
              <Typography
                style={{ paddingTop: -50, marginTop: -20, marginLeft: 4, textAlign: isScreenSmall ? 'center' : 'start' }}
                fontStyle={{ color: palette.white }}
                fontWeight={400}
                fontSize={isScreenSmall ? '24px' : '40px'}
              >
                I build and design things for the web
              </Typography>
            </Stack>
            <Typography
              style={{ marginLeft: isScreenSmall ? -8:4, textAlign: isScreenSmall ? 'center' : 'start' }}
              fontWeight={500}
              variant="h4"
              fontSize={isScreenSmall ? '20px' : '28px'}
            >
              I am a Full Stack Web and Mobile Engineer who loves creating user-friendly, engaging experiences. I work
              with both front-end and back-end technologies, making sure everything runs smoothly and securely. I enjoy
              making product and design decisions and aim to connect technical solutions with real user needs.
            </Typography>
            <Stack
              spacing={8}
              style={{
                display: 'flex',
                alignItems: isScreenSmall ? 'center' : 'space-between',
                justifyItems: isScreenSmall ? 'center' : 'space-between',
                flexBasis: isScreenSmall ? 'center' : 'space-between',
              }}
              direction={isScreenSmall ? "column" : "row"}
            >
              <Button variant="contained"  onClick={navigateToExperience}>
                Experience
              </Button>
              <Button variant="contained" onClick={navigateToProjects}>
                Projects
              </Button>
              <Button variant="contained" onClick={navigateToContact}>
                Contact
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}