import { Route, Routes } from 'react-router-dom';
import Home from '../screens/Home';
import JobHistorySection from '../components/JobHistory';
import ProjectsSection from '../components/Projects';
import TCAppPage from '../components/projectPages/TCAppPage';
import GTAppPage from '../components/projectPages/GTAppPage';
import ContactPage from '../components/ContactPage';
import KyrosAppPage from '../components/projectPages/KyrosAppPage';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" Component={Home} />
      <Route path="/experience" Component={JobHistorySection} />
      <Route path="/projects" Component={ProjectsSection} />
      <Route path="/projects/tipCalculator" Component={TCAppPage} />
      <Route path="/projects/groceryTracker" Component={GTAppPage} />
      <Route path="/projects/kyrosApp" Component={KyrosAppPage} />
      <Route path="/contact" Component={ContactPage} />
    </Routes>
  );
}
