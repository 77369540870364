import { Box, Button, Stack, Typography } from '@mui/material';
import GTImage1 from '../../components/images/GTImage1.jpg';
import GTImage2 from '../../components/images/GTImage2.jpg';
import GTImage3 from '../../components/images/GTImage3.jpg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { palette } from '../../myTheme';


export default function GTAppPage() {
  const isScreenSmall = useMediaQuery('(max-width: 900px)');
  return (
    <Box margin={isScreenSmall ? 6 : 12}>
      <Stack spacing={8}>
      <Stack direction="row" style={{ paddingBottom: 8, justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography fontWeight={800} fontSize={isScreenSmall ? 18 : 70}>
      Sprouts: Grocery Tracker
        </Typography>
        <Button style={{ marginTop: isScreenSmall ? 0 : 13, maxHeight: isScreenSmall ? 40 : 80, fontSize: isScreenSmall ? 16 : 30 }} onClick={() => window.history.back()}>
            Back
          </Button>
      </Stack>
      <Typography style={{ textAlign: isScreenSmall ? 'center' : 'start' }} fontStyle={{ color: palette.white }} fontWeight={400} fontSize={isScreenSmall ? 14 : 20}>
      Sprouts is a simple but effective way to make sure you can see which groceries are going bad and which need to be cooked soon. log your groceries and their expiration dates when you buy them and the app will let you know when specific ingredients are nearing their expiration date. </Typography>
        <Stack style={{ justifyContent: 'space-evenly' }} direction={'row'}>
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '550px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={GTImage1}
          />
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '550px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={GTImage2}
          />
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '550px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={GTImage3}
          />
        </Stack>
        <Typography variant="h3">Feature Roadmap:</Typography>
        <Stack paddingLeft={4}>
          <Typography sx={{ display: 'list-item' }}>
            Gesture to log items as Eaten: Short term. In the initial release, to mark grocery items as eaten, or as
            expired, you navigate to a sheet where you can delete the items in a list by swiping. This is a simple if
            inelegant solution. Soon the app with support a long press gesture (with a confirmation alert) to log food
            as eaten or expired directly from the home view.
          </Typography>
          <Typography sx={{ display: 'list-item' }}>
            Statistics: Short Term. With this feature, you will be able to track how you are doing with your groceries,
            how many expire before being eaten, and many more informative metrics.
          </Typography>
          <Typography sx={{ display: 'list-item' }}>
            Recipes: Medium Term. With Recipes, Sprouts is going to level up as a grocery tracker. You can group your
            grocery items into recipes and log them as consumed with 1 click. An example would be you cook a meal that
            uses 1 pound of chicken breasts, 8 ounces of mushrooms, and 1 pound of Brussels sprouts. You simply log in
            the app you made this saved recipe and it will erase the items and their quantities from your grocery
            tracking list. This feature will most likely come in a few phases with Sprouts eventually recommending saved
            recipes based on what you have logged.
          </Typography>
          <Typography sx={{ display: 'list-item' }}>
            Barcode Scanning: Long Term. Bardcode scanning to allow for easy logging is a feature that I think will
            really take this app to the next level of usefullness. This feature will allow nearly instant logging as you
            put your groceries way and eliminate the manual entry step that is very time consuming, especially for big
            orders.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
