import { Box, Button, Stack, Typography } from '@mui/material';
import { palette } from '../../myTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import KyrosImage1 from '../images/kyrosImage1.jpg';
import KyrosImage2 from '../images/kyrosImage2.jpg';
import KyrosImage3 from '../images/kyrosImage3.jpg';
import KyrosImage4 from '../images/kyrosImage4.jpg';
import KyrosImage5 from '../images/kyrosImage5.jpg';




export default function KyrosAppPage() {
  const isScreenSmall = useMediaQuery('(max-width: 900px)');
  return (
    <Box margin={isScreenSmall ? 6 : 12}>
      <Stack spacing={8}>
      <Stack direction="row" style={{ paddingBottom: 8, justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography fontWeight={800} fontSize={isScreenSmall ? 18 : 70}>
          Kyros - Recovery Support
        </Typography>
        <Button style={{ marginTop: isScreenSmall ? 0 : 13, maxHeight: isScreenSmall ? 40 : 80, fontSize: isScreenSmall ? 16 : 30 }} onClick={() => window.history.back()}>
            Back
          </Button>
      </Stack>

        <Typography style={{ textAlign: isScreenSmall ? 'center' : 'start' }} fontStyle={{ color: palette.white }} fontWeight={400} fontSize={isScreenSmall ? 14 : 20}>
        Kyros offers a transformative digital platform designed to guide individuals on their recovery wellness journey. Seamlessly connecting users with professional support and essential resources, Kyros revolutionizes the landscape of holistic recovery. Our human-centric approach ensures personalized care, fostering positive outcomes for millions impacted by substance use challenges.
        Experience the power of the Kyros platform, empowering you to navigate your path to recovery.
        </Typography>
        <Stack gap={2}>
        <Typography>Key Features</Typography>
        <Stack paddingLeft={4}>
        <Typography sx={{ display: 'list-item' }}>Direct access to professional support</Typography>
        <Typography sx={{ display: 'list-item' }}>Intuitive and user-friendly</Typography>
        <Typography sx={{ display: 'list-item' }}>Geolocation based check in system</Typography>

        </Stack>
        <Stack style={{ justifyContent: 'space-evenly' }} direction={'row'}>
        <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '450px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={KyrosImage1}
          />
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '450px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={KyrosImage2}
          />
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '450px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={KyrosImage3}
          />
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '450px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={KyrosImage4}
          />
          <Box
            component="img"
            sx={{
              display: 'flex',
              placeItems: 'center',
              placeContent: 'center',
              width: 'auto',
              height: '450px',
              justifyContent: 'space-evenly',
              borderRadius: '15px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            alt="app icon"
            src={KyrosImage5}
          />
</Stack>
        </Stack>
        </Stack>
    </Box>
  );
}
